import {
  Button,
  ButtonGroup,
  Collapse,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { Container } from "@mui/system";
import { StaticImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import Seo from "../assets/components/seo";

import Navbar from "../assets/components/Navbar";
import { graphql } from "gatsby";
import { useTranslation } from "react-i18next";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import Footer from "../assets/components/Footer";

const Historia = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const { t } = useTranslation();
  const theme = useTheme();

  const handleClick = (index) => {
    if (index === openIndex) {
      setOpenIndex(null);
    } else {
      setOpenIndex(index);
    }
  };

  return (
    <Container
      maxWidth={false}
      sx={{ padding: "0 !important", margin: "0 !important" }}
    >
      <Seo />
      <Navbar />

      <Grid container justifyContent="center" px={2} pb={8} spacing={8}>
        <Grid item container maxWidth={1400} marginTop={12}>
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h3"
                color="white"
                position="absolute"
                textAlign="center"
              >
                {t("principal")}
              </Typography>
              <img
                src="https://imagenturistica.tabasco.gob.mx/_data/i/upload/2023/07/03/20230703153036-31df74fb-me.jpg"
                alt="festival del chocolate tabasco"
                style={{
                  width: "100%",
                  height: 228,
                  objectFit: "cover",
                }}
              />
            </div>
          </Grid>
        </Grid>
        <Grid item container maxWidth={1400}>
          <Grid
            item
            container
            xs={12}
            md={4}
            spacing={1}
            display={{ xs: "none", md: "flex" }}
          >
            {t("articles", { returnObjects: true }).map((article, i) => (
              <Grid item component="a" href={`#${article.title}`}>
                <Typography color="#6c3f37" fontWeight="bold">
                  {article.title}
                </Typography>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12} md={8} paddingLeft={{ md: 12 }}>
            <Typography color="#FF5E00" fontWeight="bold" fontSize={20}>
              {t("principal")}
            </Typography>
            <Typography variant="body2" color="GrayText" marginTop={2}>
              {t("mainArticle")}
            </Typography>
          </Grid>
          <Grid item container spacing={4} paddingTop={6}>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={12} display={{ md: "none" }}>
                <List>
                  {t("articles", { returnObjects: true }).map((article, i) => (
                    <React.Fragment key={i}>
                      <ListItemButton onClick={() => handleClick(i)}>
                        {openIndex === i ? (
                          <ExpandLess htmlColor="#6c3f37" />
                        ) : (
                          <ExpandMore htmlColor="#6c3f37" />
                        )}
                        <Typography
                          color="#6c3f37"
                          fontWeight="500"
                          fontSize={18}
                          paddingLeft={2}
                        >
                          {article.title}
                        </Typography>
                      </ListItemButton>
                      <Collapse
                        in={openIndex === i}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <Typography
                            variant="body2"
                            color="GrayText"
                            paddingY={2}
                          >
                            {article.description}
                          </Typography>
                          {article.images.map((image) => (
                            <div style={{ flex: 1, textAlign: "center" }}>
                              <img
                                src={image}
                                style={{
                                  maxWidth: 300,
                                  maxHeight: 500,
                                  objectFit: "cover",
                                  paddingBottom: theme.spacing(2),
                                }}
                                alt="Article"
                              />
                            </div>
                          ))}
                        </List>
                      </Collapse>
                      <Divider />
                    </React.Fragment>
                  ))}
                </List>
              </Grid>
              {t("articles", { returnObjects: true }).map((article, i) => (
                <Grid
                  item
                  container
                  marginTop={2}
                  spacing={2}
                  display={{ xs: "none", md: "flex" }}
                  component="div"
                  id={article.title}
                >
                  <Grid item xs={12}>
                    <Typography color="#FF5E00" fontWeight="bold" fontSize={20}>
                      {article.title}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8} paddingRight={{ md: 4 }}>
                    <Typography variant="body2" color="GrayText">
                      {article.description}
                    </Typography>
                  </Grid>

                  <Grid item md={4}>
                    {article.images.map((image) => (
                      <div
                        style={{
                          flex: 1,
                          textAlign: "center",
                        }}
                      >
                        <img
                          src={image}
                          style={{
                            maxWidth: 400,
                            objectFit: "cover",
                          }}
                          alt="Article"
                        />
                      </div>
                    ))}
                  </Grid>
                </Grid>
              ))}

              <Grid item container xs={12}>
                <Grid item xs={12}>
                  <div
                    style={{
                      height: 1,
                      width: "100%",
                      backgroundColor: "#6c3f37",
                    }}
                  />
                </Grid>
                <Grid item xs={12} paddingTop={2}>
                  <Typography fontWeight="bold" fontStyle="italic">
                    Fuentes y Bibliografía
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    style={{ whiteSpace: "pre-wrap", color: "GrayText" }}
                    variant="caption"
                  >
                    Vela, E. (2004). Grandes Culturas de Tabasco. MAYAS OLMECAS
                    ZOQUES. Editorial Raíces, S.A de C.V. {"\n"}1. (2022, 25
                    julio). ▷ ¿Cuál es el origen del chocolate? Torras.{"\n"}
                    https://chocolatestorras.com/origen-del-chocolate/ {"\n"}
                    Cronología del chocolate: Historia del cacao. (2022, 3
                    junio). Universidad de Oriente {"\n"}Veracruz.
                    https://veracruz.uo.edu.mx/blog/cronolog%C3%ADa-del-chocolate-
                    {"\n"}historia-del-cacao {"\n"}Tabasco Histórico. (2021).
                    Editorial México Desconocido, S.A de C.V. {"\n"}Wikipedia:
                    {"\n"}
                    Evan Grivetti, Louis (2009). Chocolate History, culture
                    Heritage (en inglés) (1ª edición). New Jersey: Willey &
                    Sons. ISBN 978-0-470-12165-8. – Se trata de una de los
                    estudios más reputados acerca de las propiedades del
                    chocolate y de su historia, así como de la influencia
                    social. Montal Montesa, Rafael (2000). El chocolate “Las
                    semillas de oro” (1ª edición). Zaragoza: Gobierno de Aragón.
                    ISBN 84-7753-778-X.
                    {"\n"}Martí Escayol, Maria Antònia, El plaer de la xocolata.
                    La Història i la cultura de la xocolata a Catalunya,
                    Editorial Cossetània, Valls, 2004.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Footer />
    </Container>
  );
};

export default Historia;

export const QUERY_TRANSLATION = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["historia"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
